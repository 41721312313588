.nfts{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  width: 100%;
  flex-wrap: wrap;
}

.nft-container{
  display:flex;
  flex-direction:column;
  width:400px;
  padding:40px;
  border:1px solid black;
  margin-right:50px;
  margin-bottom:50px;
}

.nft-container h5{
  font-size:20px;
}

.nft-image{
  width:300px;
}

#loading{
  position:fixed;
  z-index:9;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  top:200px;
}
